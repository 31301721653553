<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content" id="middleArea">
            <div class="more-row" v-for="(item, index) in pagelist.list" :key="index">
                <img :src='item.img'>
                <div class="more-right" @click="details(item.id)">
                    <div>
                        <h3>{{ item.title }}</h3>
                        <p class="desc">{{ item.contents }}</p>
                    </div>
                    <p class="time">{{ item.createTime }}</p>
                </div>
            </div>
            <div class="flex-acjc">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                    :total=this.total>
                </el-pagination>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { PageTeachNewList } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            currentPage: 1,//初始页      
            pagesize: 12,//每页的数据            
            users: {},//用户信息
            total: 0,//总数
            listQuery: {
                pageIndex: this.currentPage,
                pageSize: this.pagesize,
                newsTypeId: 2
            },
            pagelist: [],
        }
    },
    created() {
        //各地教研列表
        this.onPageTeachNewList();
    },
    methods: {
        //各地教研列表
        onPageTeachNewList() {
            this.listLoading = true;
            this.listQuery.pageIndex = this.currentPage,
            this.listQuery.pageSize = this.pagesize,
            PageTeachNewList(this.listQuery).then(response => {
                if (response) {
                    if (response.status == 200) {
                        this.pagelist = response.data;
                        this.total = response.data.total;
                    } else {
                        MessageBox.alert(response.message, "错误", {
                            confirmButtonText: '确定',
                            type: 'error'
                        })
                    }
                    setTimeout(() => {
                        this.listLoading = false
                    }, 1.5 * 1000)
                }
            })
        },
        //各地教研点击
        details(id){
            this.$router.push({
                name: '/teachingDetail',
                params: {
                    "id": id,
                    "mdd": this.$refs.nav.mdd
                }
            });
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.onPageTeachNewList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.onPageTeachNewList();
        }
    },
	mounted() {
        this.$refs.nav.mdd = 3;
        localStorage.setItem("mdd", 3);
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;

        .more-row {
            background-color: #fff;
            display: flex;
            height: 140px;
            margin-bottom: 20px;
            cursor: pointer;

            img {
                width: 220px;
                margin-right: 20px;
                display: block;
            }

            .more-right {
                padding-right: 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    color: #595959;
                    font-size: 16px;
                    font-weight: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .desc {
                    color: #c3c4c6;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .time {
                    text-align: right;
                    font-size: 12px;
                    line-height: 20px;
                    color: #999;
                }

                &:hover {
                    h3 {
                        color: #469bd7;
                    }
                }
            }
        }

    }
}</style>